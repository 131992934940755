import React, { useState, useEffect } from 'react';
import Field from '../../Field/Field';
import CTAButton from '../../CTAButton/CTAButton';
import "./EnterPasswordModal.scss";
import KaleidoscopeAPI from '../../../Core/KaleidoscopeAPI';
import { toast } from 'react-toastify';
import { MAIN_CONFIG } from '../../../../config/main';
import SSoAccountSwitch from '../../SSoAccountSwitch/SSoAccountSwitch';
import FlashMessage from '../../FlashMessage/Message';
import { API_CONFIG } from '../../../../config/api';
import { handleKeyDown } from '../../../Utility/ApplicationFormUtility';
import { handleRedirectHrefUrl } from '../../../Utility/HelperFunctions';



export default ({
  email,
  handleClose = () => { },
  setLoading = () => { },
  handleLogin = () => { },
  showSSoButton ,
  ssoAccounts,
  setModalState = ()=> {},
  onCloseCheck = ()=>{}
}) => {

  const [password, setPassword] = useState('');
  const [emailAddress, setEmail] = useState(email);
  const [forgotPassword, setForgotPassword] = useState(false);

  const API = new KaleidoscopeAPI({});


  const onClose = (e) => {
    handleClose()
    // window.location.href = "/"
  }

  const handleSubmit = (e , email , password) => {
    e.preventDefault()
    handleLogin(email, password)
  }

  const handleSend = async (e , email = "") => {
    setLoading(true)
    e.preventDefault();
    // let email = { "email": email }
    const response = await API.sendPasswordLink({ token: "", email: { "email": email } })
    if (response.success) {
        toast.success(<FlashMessage message={response.message} />);
        setLoading(false)
    } else {
        toast.error(<FlashMessage type='error' message={response.message} />);
        setLoading(false)
    }

}

  const handleSendEmail = (e) => {
    e.preventDefault()
    handleSend(e , email)
  }

  const samlApiCall = async (accountOBJ)=>{
    setLoading(true)
    // const response = await API.fetchSamlSSOUrl({ accountId: accountOBJ.sfid})
    // if (response.saml_request_url) {
    //     window.location.href = response.saml_request_url
    //     setLoading(false)
    // } else {
    //     toast.error(response.message || MAIN_CONFIG.SSO_ERROR)
    //     setLoading(false)
    // }
    handleRedirectHrefUrl(`${API_CONFIG.BASE_API_URL}${API_CONFIG.API_VERSION_PATH}/sso/init?idp_uniq_id=${accountOBJ.sfid}`)
  }
  const enterKeyHandler = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      onClose()
    }
  }
  

  const handleSSoLogin = async (e)=>{
    e.preventDefault()
    if(ssoAccounts.length === 1){
      samlApiCall(ssoAccounts[0])
    }else{
      setModalState({
        visible: true,
        id: 'enter-password-modal__create_SSO',
        class: 'application_step_modal',
        content: <SSoAccountSwitch
        ssoAccounts = {ssoAccounts}
        samlApiCall = {samlApiCall}
        onCloseCheck = {onCloseCheck}
        setModalState = {setModalState}
        />
    });
    }
  }

  return (
    <div id='task-modal-content'>
      <div className="enter-password-modal">
        <div className="task-head_container">
        {
          forgotPassword ? <div className="H1DesktopGreen">Forgot Your Password?</div>
            :
            <div className="H1DesktopGreen" style={{ paddingBottom: 30 }}>Sign In To Your Account</div>
        }
          <div className="event-head">
          <a tabIndex={0} role='button' onKeyDown={enterKeyHandler} aria-label='close modal' className="event-head H6DesktopGrey cancel-icon" onClick={onClose}>X</a>
          </div>
        </div>
        
        {
          forgotPassword ? ""
            :
            <div className='CTAGrey short-description'>It looks like you already have a Kaleidoscope account.</div>
        }
        
        <form onSubmit={(e) => !forgotPassword ? handleSubmit(e , emailAddress , password) : handleSendEmail(e)}>
          <div className="enter-password-modal__field-container">
            
          {showSSoButton && !forgotPassword && <>
            <div className="sso-button-login">
                    <CTAButton
                      id='task-page__cta'
                      type='tertiary' onClick = {(e)=> handleSSoLogin(e)}>
                      Sign In With SSO
                    </CTAButton>
                  </div>
                     <span className='enter-password-label'>Or Enter Your Password</span>
                     <Field
                       label=''
                       id={'question_id'}
                       className="application-section__textarea"
                       placeholder='Password'
                       type={'password'}
                       required={true}
                       handleChange={(value) => setPassword(value)}
                     />
          </>
                  }
                
                  
            {
              forgotPassword ?
                <Field
                  id='login-page__email'
                  label='Email Address'
                  placeholder=''
                  type='email' value={emailAddress}
                  handleChange={setEmail} required
                />
                :
                <>
               {!showSSoButton && <Field
                  label='Enter Your Password'
                  id={'question_id'}
                  className="application-section__textarea"
                  placeholder='Password'
                  type={'password'}
                  required={true}
                  handleChange={(value) => setPassword(value)}
                />}
                </>   
            }
          </div>

          <div className="enter-password-modal__button-container">
            {
              forgotPassword ?
                <>
                  <CTAButton
                    id='password-page__cta'
                    type='tertiary'>
                    Recover Password
                  </CTAButton>
                </>
                :
                <>
                  <div>
                    <CTAButton
                      id='password-page__cta'
                      type='tertiary'>
                      Sign In
                    </CTAButton>
                  </div>
                  <div className='CTAGrey forgot-password-message-and-link'>
                    Forgot your password? <a className="" href="#" onClick={(e) => { e.preventDefault(); setForgotPassword(true) }}>Click here</a>
                  </div>
                </>
            }
          </div>
        </form>
      </div>
    </div>
  )
}